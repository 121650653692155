import React from 'react';
import store from '../../store';
import * as listApi from '../../api/list-api';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';
import { removeCreateButtonUsers, exportUsers, usedPromoCodeExportUsers, markInvoicedUsers } from '../../configurations/general-conf';

const PagenationLink = styled.p`
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
  float: right;

  & button {
    background: none;
    border: 1px solid;
    padding: 1px 2px;
    margin: 0 3px;
    cursor: pointer;
    color: ${theme.mainLighterColor};
  }

  & span {
    font-size: 13px;
    color: ${theme.mainDarkColor};
  }
`;

const TotalRecordsContainer = styled.div`
  margin-left: 14px;
  color: ${theme.mainColor};
`;

const FooterButtonsContainer = styled.div`
  float: right;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const FooterButton = styled.button`
  height: 30px;
  width: 110px;
  border: none;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  background-color: ${
    props => props.createNew ? theme.mainColor : props.deleteBatch ? theme.failureColor : props.invoicedBatch ||  props.processedBatch ? '#0096e1' : props.export || props.directExport ? '#fc9303' : theme.mainDarkColor
  };

  &:hover {
    background-color: ${
      props => props.createNew ? theme.mainLighterColor : props.deleteBatch ? theme.failureLightColor : props.invoicedBatch ? '#00b0e1' : props.export || props.directExport ? '#fdae42' : theme.mainDarkColorHover
    }
  }
`;

export default class extends React.Component{

  changePage = (toPage, e) => {
    e.preventDefault();
    if(this.props.nameSpace){
      listApi.getList(this.props.nameSpace, toPage);
    }
  }

  deleteBatch = () => {
    const { selection } = store.getState()[this.props.nameSpace];
    if (selection.length>0) {
      if (window.confirm('Do you really want to delete ' + selection.length + ' item(s)?')){
        listApi.deleteBatch(this.props.nameSpace);
      } else {
        console.log('cancelled');
      }
    }
  }

  invoicedBatch = () => {
    const { selection } = store.getState()[this.props.nameSpace];
    if (selection.length>0) {
      if (window.confirm('Click "OK" to mark ' + selection.length + ' item(s) as Invoiced. Only "Dispatched" order(s) can be marked.')){
        listApi.invoicedBatch(this.props.nameSpace);
      } else {
        console.log('cancelled');
      }
    }
  }

  processedBatch = () => {
    const { selection } = store.getState()[this.props.nameSpace];
    if (selection.length>0) {
      if (window.confirm('Click "OK" to mark ' + selection.length + ' item(s) as Processed. Only "Confirmed" return order(s) can be marked.')){
        listApi.processedBatch(this.props.nameSpace);
      } else {
        console.log('cancelled');
      }
    }
  }
  
  prePage = (currentPage) => {
    if(currentPage===1){
			return (<span>&lt;&lt;&nbsp;</span>);
		}else{
			return (<button onClick={this.changePage.bind(null, currentPage-1)} >&lt;&lt;&nbsp;</button>);
		}
  }

  numberNav = (currentPage) => {
    let numberLinks = [];
    const { totalRecords, pageSize } = store.getState()[this.props.nameSpace];
		const totalPage = Math.ceil(totalRecords/pageSize);
		for (let i = 1; i <= totalPage; i++) {
			const linkStr = i.toString().length===1 ? `0${i}` : i;
			//let output = true;
			if(currentPage===i){
				numberLinks.push(<span key={2*i-1}>{linkStr}</span>);
			}else{
				if(totalRecords>=200){
					if(i===1||i===totalPage){
						numberLinks.push(<button key={2*i-1} onClick={this.changePage.bind(null, i)} >{linkStr}</button>);
					}else if(i>currentPage-5 && i<currentPage+5){
						numberLinks.push(<button key={2*i-1} onClick={this.changePage.bind(null, i)} >{linkStr}</button>);
					}else if((i===currentPage-5&&i>=2)||(i===currentPage+5&&i<totalPage)){
						numberLinks.push(<span key={`p${2*i}`}>&nbsp;...&nbsp;</span>);
					}else{
						//output = false;
					}
				}else{
					numberLinks.push(<button key={2*i-1} onClick={this.changePage.bind(null, i)} >{linkStr}</button>);
				}
			}
			// if(i<totalPage&&output){
			// 	numberLinks.push(<span key={2*i} className="pageSeperator">&nbsp;|&nbsp;</span>);
			// }
		}
		return (<span>{numberLinks}</span>);
  }

  nextPage = (currentPage) => {
    const { totalRecords, pageSize } = store.getState()[this.props.nameSpace];
    if(currentPage===Math.ceil(totalRecords/pageSize)){
			return (<span>&nbsp;&gt;&gt;</span>);
		}else{
      return (<button onClick={this.changePage.bind(null, currentPage+1)} >&nbsp;&gt;&gt;</button>);
    }
  }

  exportCSV = (nameSpace, exUrl) => {
    listApi.exportCSV(nameSpace, exUrl);
  }

  directExportCSV = (exUrl) => {
    listApi.directExportCSV(exUrl);
  }

  render (){
    //console.log(store.getState()[this.props.nameSpace].totalRecords);
    const { currentPage, totalRecords } = store.getState()[this.props.nameSpace];
    return (
      <div>
        <TotalRecordsContainer>
          <span>Total Records: {totalRecords}</span>
        </TotalRecordsContainer>

        <FooterButtonsContainer>
					{this.props.buttons.map((button,i) => {
						switch(button.type) {
							case 'createNew':
                let displayField = true;
                if(button.adminUserOnly === true){
                  displayField = "0" === restful.getCookie("userType") ? true : false;
                }
                if (removeCreateButtonUsers.some(element => {
                    return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
                  })){
                  displayField = false;
                }
                return (
                  displayField ?
                  <FooterButton
                    key={i}
                    onClick={() => this.props.history.push(window.location.pathname + '/new')}
                    createNew
                  >
                    {button.text}
                  </FooterButton>
                  : null
                );
							case 'deleteBatch':
								return (
                  <FooterButton
                    key={i}
                    onClick={this.deleteBatch}
                    deleteBatch
                  >
                    {button.text}
                  </FooterButton>);
              case 'invoicedBatch':
                return (
                  markInvoicedUsers.some(element => {
                    return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
                  }) ? 
                  <FooterButton
                    key={i}
                    onClick={this.invoicedBatch}
                    invoicedBatch
                  >
                    {button.text}
                  </FooterButton>
                  : null);
              case 'processedBatch':
                return (
                  markInvoicedUsers.some(element => {
                    return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
                  }) ? 
                  <FooterButton
                    key={i}
                    onClick={this.processedBatch}
                    processedBatch
                  >
                    {button.text}
                  </FooterButton>
                  : null);
              case 'directExport':
                let showUsedPromoExport = false;
                if (usedPromoCodeExportUsers.some(element => {
                    return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
                  })) {
                  showUsedPromoExport = true;
                }
                //Check Used Promo Code direct export with 
                if (this.props.nameSpace === "saleOrder") {
                  return (showUsedPromoExport ? <FooterButton key={i} onClick={() => this.directExportCSV(button.exportUrl)} directExport>{button.text}</FooterButton> : null);
                } else {
                  return (<FooterButton key={i} onClick={() => this.directExportCSV(button.exportUrl)} directExport>{button.text}</FooterButton>);
                }
              case 'export':
                let showExport = false;
                if (exportUsers.some(element => {
                    return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
                  })) {
                  showExport = true;
                }
                return ((showExport && store.getState()[this.props.nameSpace].currentQuerry) ? <FooterButton key={i} onClick={() => this.exportCSV(this.props.nameSpace, button.exportUrl)} export> {button.text} </FooterButton> : null);
              case 'reset':
                return (<FooterButton key={i} onClick={this.props.resetResults}> {button.text} </FooterButton>);
              default:
						}
						return true;
					})}
				</FooterButtonsContainer>
				
				<PagenationLink>
					{this.prePage(currentPage)}
					{this.numberNav(currentPage)}
					{this.nextPage(currentPage)}
				</PagenationLink>
			</div>
    );
  }
}