import React from 'react';
import styled from 'styled-components';
import { theme, adminCompanyName, resetOrderUsers } from '../../configurations/general-conf';
import momentTz from 'moment-timezone';
import Extension from '../containers/extension-container';
import * as restful from '../../api/restful-api';
import { getWhname } from '../widgets/utilities';
import store from '../../store';
// import _ from 'lodash';

const ListBodyContainer = styled.div`
  min-height: 470px;
  margin-bottom: 10px;
`;
const ListItemSpan = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;

  color: ${
    props => props.colorMark ? theme.successColor : (props.colorRender ? (props.colorSuccess ? theme.successColor : (props.colorWarning ? theme.warningColor : theme.failureColor)) : null)
  };

  font-family: ${
    props => props.bold ? theme.boldFont : null
  };

  font-size: ${
    props => props.bold ? '14px' : null
  };
`;
const TextSpan = styled.span`
  background-color: ${
    props => props.bgRender ? 'yellow' : null
  };
`;
const ListItemSpanHand = styled(ListItemSpan)`
  color: ${
    props => props.spanColor === 0 ? theme.failureColor : props.spanColor === 1 ? theme.successColor : null
  };
  cursor: pointer;
  text-decoration: underline;
`;
const ItemList = styled.ul`
  list-style: none;
  padding-left: 0;
`;
const ListRow = styled.li`
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: 4px solid transparent;
  line-height: 14px;

  border-top: ${props => props.groupSeparate ? '1px solid lightblue' : 'none'};
  margin-top: ${props => props.groupSeparate ? '20px' : '0'};

  &:nth-child(odd) {
    background-color: ${theme.mainBgColor}
  }

  &:hover {
    border-left: 4px solid ${theme.mainColor}
  }
`;
const RoundCheckContainer = styled.div`
  float: left;
  width: 16px;
  height: 20px;
  background: #ddd;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,.5);
  margin-top: 1px;
  margin-left: 3px;
  display: inline-block;
`;
const CheckBoxInput = styled.input`
  visibility: hidden;
  &:checked+label {
    background: blue;
  }
`;
const RoundCheckLabel = styled.label`
  display: block;
  width: 16px;
  height: 20px;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #d3d3d3;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.5);
`;
export const BtnContainer = styled.span`
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;

  & button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
`;
const DamageTypeSpan = styled.span`
  color: ${
    props => props.status === "opt-1" || props.status === "opt-2" ? theme.failureColor : props.status === "opt1" || props.status === "opt2" ? theme.successColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const AccountStatusSpan = styled.span`
  color: ${
    props => props.status === "opt4" ? theme.mainColor : props.status === "opt3" ? theme.failureColor : props.status === "opt2" ? theme.warningColor : props.status === "opt1" ? theme.successColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const ContainerStatusSpan = styled.span`
  color: ${
    props => props.status === "opt4" ? theme.successColor : props.status === "opt3" ? 'orange' : props.status === "opt2" ? '#00AEEF' : props.status === "opt1" ? 'purple' : props.status === "opt0" ? theme.failureColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const OrderStatusSpan = styled.span`
  color: ${
    props => props.status === "opt0" || props.status === "opt-1" || props.status === "opt-2" ? theme.failureColor : props.status === "opt1" ? theme.warningColor : props.status === "opt2" ? '#ff8c00' : props.status === "opt3" || props.status === "opt4" ? theme.successColor : props.status === "opt5" ? theme.mainEditColor : props.status === "opt6" ? '#9370db' : props.status === "opt9" ? 'blue' : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const ReturnOrderStatusSpan = styled.span`
  color: ${
    props => props.status === "opt0" || props.status === "opt-1" || props.status === "opt-2" ? theme.failureColor : props.status === "opt1" ? theme.warningColor : props.status === "opt2" ? '#ff8c00' : props.status === "opt3" ? theme.successColor : props.status === "opt4" ? 'blue' : props.status === "opt10" ? theme.failureColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const OrderTypeSpan = styled.span`
  color: ${
    props => props.status === "opt0" || props.status === "opt100" ? '#caa2dd' : props.status === "opt1" || props.status === "opt101" ? '#00B0F0' : props.status === "opt2" || props.status === "opt102" ? '#FF9900' : props.status === "opt9" ? '#FF0000' : props.status === "opt10" ? '#9E30A0' : props.status === "opt11" ? '#EF4B81' : props.status === "opt12" ? '#B33D26' : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const PortalSpan = styled.span`
  color: ${
    props => props.portal === "IMS" ? theme.mainLighterColor : '#008BFF'
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const ReuseStatusSpan = styled.span`
  color: ${
    props => props.status === "opt1" ? theme.failureColor : props.status === "opt999999" ? theme.successColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const HistoryFlagSpan = styled.span`
  color: ${
    props => props.flag === "opt-12" || props.flag === "opt-11" || props.flag === "opt-1" || props.flag === "opt-2" ? theme.failureColor : props.flag === "opt1" || props.flag === "opt2" || props.flag === "opt11" || props.flag === "opt12" || props.flag === "opt13" || props.flag === "opt14" ? theme.successColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const UserStatusSpan = styled.span`
  color: ${
    props => props.status === "opt0" ? theme.failureColor : props.status === "opt1" ? theme.successColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const WarehouseSpan = styled.span`
  color: ${
    props => props.addWarehouse ? theme.failureColor : null
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const CompletedQtySpan = styled.span`
  color: ${
    props => props.completedQty < props.planQty ? theme.failureColor : theme.successColor
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;
const CompletedDateSpan = styled.span`
  color: ${
    props => !props.completedDate ? null : props.completedDate > props.planDate ? theme.failureColor : theme.successColor
  };
  display: inline-block;
  vertical-align: middle;
  padding-left: 14px;
`;

const groupSortAndFlattenWithInnerSort = (arr, groupKey, sortKey) => {
  const groups = arr.reduce((acc, item) => {
    const key = item[groupKey];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const sortedGroups = Object.entries(groups)
    .map(([groupName, items]) => ({
      groupName,
      items: items.sort((a, b) => (isNaN(a[sortKey]) ? 999999 : a[sortKey]) - (isNaN(b[sortKey]) ? 999999 : b[sortKey])),
      minValue: Math.min(...items.map(item => (isNaN(item[sortKey]) ? 999999 : item[sortKey])))
    }))
    .sort((a, b) => a.minValue - b.minValue);

  return sortedGroups.flatMap(group => group.items);
};

export default (props) => {
  const fixWidthRatio = props.buttonAreaWidth / window.innerWidth;
  const showCheckbox = props.showCheckbox;
  const textFilter = store.getState()[props.nameSpace].filters.find( filter => filter.type === 'text')?.currentValue;

  let list = props.list;

  if(props.list && props.list.length > 0 && "m1" in props.list[0] && "m24" in props.list[0]){
    props.list.map( listItem => {
      const uSizeItem = listItem['unitSize'] ? listItem['unitSize'] : 1;
      const A = listItem.m1 * uSizeItem;
      const B = listItem.m2 * uSizeItem;
      const C = listItem.m3 * uSizeItem;
      const D = (listItem.m4 + listItem.m5 + listItem.m6) / 3 * uSizeItem;
      const E = (listItem.m7 + listItem.m8 + listItem.m9 + listItem.m10 + listItem.m11 + listItem.m12) / 6 * uSizeItem;
      const F = (listItem.m13 + listItem.m14 + listItem.m15 + listItem.m16 + listItem.m17 + listItem.m18 + listItem.m19 + listItem.m20 + listItem.m21 + listItem.m22 + listItem.m23 + listItem.m24) / 12 * uSizeItem;
      listItem.ave4_6 = D.toFixed(3);
      listItem.ave7_12 = E.toFixed(3);
      listItem.ave13_24 = F.toFixed(3);
      let pms = 0;
      if(F>20){
        pms = 0.18 * (A + B + C) + 0.16 * (D + E) + 0.14 * F
      }else if(E>20){
        pms = 0.22 * (A + B + C) + 0.17 * (D + E)
      }else if(D>20){
        pms = 0.26 * (A + B + C) + 0.22 * D
      }else if(C>20){
        pms = 0.333 * (A + B + C)
      }else if(B>20){
        pms = 0.5 * (A + B)
      }else if(A>20){
        pms = A
      }
      listItem.pms = (pms / uSizeItem).toFixed(3);
      listItem.psl = listItem.avs / listItem.pms;
      listItem.res = (listItem.pms * 2.5 - listItem.avs).toFixed(3);
      return listItem;
    });

    // list = _.orderBy(props.list, ['productCategoryName','productName','psl'], ['asc']);

    list = groupSortAndFlattenWithInnerSort(list, 'productName', 'psl');

    let previousProductName = '';
    list = list.map( listItem => {
      if(previousProductName !== listItem.productName){
        listItem.isFirst = true;
      }else{
        listItem.isFirst = false;
      }
      previousProductName = listItem.productName;
      return listItem;
    });

    // console.log(list);
  }
  
  return (
    <ListBodyContainer>
      <ItemList>
        {list && list.map(listItem => {
          return(
            <ListRow key={listItem[props.cfgs[0].valueKey]} groupSeparate ={listItem.isFirst} onClick={ props.extensionCfg ? props.toggleExtension.bind(null, listItem[props.cfgs[0].valueKey]) : null }>
              {props.cfgs.map( (cfg, i) => {
                const width = {width: cfg.widthRatio * (1 - fixWidthRatio) * 100 + '%'};
                // if(listItem.m1!==null && listItem.m24!==null){
                //   const uSizeItem = listItem['unitSize'] ? listItem['unitSize'] : 1;
                //   const A = listItem.m1 * uSizeItem;
                //   const B = listItem.m2 * uSizeItem;
                //   const C = listItem.m3 * uSizeItem;
                //   const D = (listItem.m4 + listItem.m5 + listItem.m6) / 3 * uSizeItem;
                //   const E = (listItem.m7 + listItem.m8 + listItem.m9 + listItem.m10 + listItem.m11 + listItem.m12) / 6 * uSizeItem;
                //   const F = (listItem.m13 + listItem.m14 + listItem.m15 + listItem.m16 + listItem.m17 + listItem.m18 + listItem.m19 + listItem.m20 + listItem.m21 + listItem.m22 + listItem.m23 + listItem.m24) / 12 * uSizeItem;
                //   listItem.ave4_6 = D.toFixed(3);
                //   listItem.ave7_12 = E.toFixed(3);
                //   listItem.ave13_24 = F.toFixed(3);
                //   let pms = 0;
                //   if(F>20){
                //     pms = 0.18 * (A + B + C) + 0.16 * (D + E) + 0.14 * F
                //   }else if(E>20){
                //     pms = 0.22 * (A + B + C) + 0.17 * (D + E)
                //   }else if(D>20){
                //     pms = 0.26 * (A + B + C) + 0.22 * D
                //   }else if(C>20){
                //     pms = 0.333 * (A + B + C)
                //   }else if(B>20){
                //     pms = 0.5 * (A + B)
                //   }else if(A>20){
                //     pms = A
                //   }
                //   listItem.pms = (pms / uSizeItem).toFixed(3);
                //   listItem.psl = listItem.avs / listItem.pms;
                //   listItem.res = (listItem.pms * 2.5 - listItem.avs).toFixed(3);
                // }
                switch(cfg.type){
                  case 'checkbox':
                    return( showCheckbox ? 
                      <RoundCheckContainer key={i}>
                        <CheckBoxInput
                          type="checkbox"
                          id={`rounded${listItem[cfg.valueKey]}`}
                          checked={props.selection&&props.selection.indexOf(listItem[cfg.valueKey])>-1}
                          onChange={props.changeSelection&&props.changeSelection.bind(null, listItem[cfg.valueKey])}
                          onClick={function(event){event.stopPropagation();}}
                        />
                        <RoundCheckLabel id={`dummy${listItem[cfg.valueKey]}`} htmlFor={`rounded${listItem[cfg.valueKey]}`} onClick={function(event){event.stopPropagation();}} />
                      </RoundCheckContainer> 
                      : null
                    );
                  case 'accountstatus':
                    let accStatus = 'opt'+ (listItem[cfg.valueKey]?.toString() || "1");
                    let accStatusValue = cfg.map[accStatus];
                    return(<AccountStatusSpan key={i} status={accStatus} style={width}>{accStatusValue}</AccountStatusSpan>);
                  case 'reusestatus':
                    let reuseStatus = 'opt'+ (listItem[cfg.valueKey]?.toString() || "1");
                    let reuseStatusValue = cfg.map[reuseStatus];
                    return(<ReuseStatusSpan key={i} status={reuseStatus} style={width}>{reuseStatusValue}</ReuseStatusSpan>);
                  case 'userstatus':
                    let userStatus = 'opt'+ (listItem[cfg.valueKey]?.toString() || "0");
                    let userStatusValue = cfg.map[userStatus];
                    return(<UserStatusSpan key={i} status={userStatus} style={width}>{userStatusValue}</UserStatusSpan>);
                  case 'localtime':
                    const iTime = listItem[cfg.valueKey] ? momentTz.tz(listItem[cfg.valueKey], momentTz.tz.guess()).format('DD/MM/YYYY HH:mm:ss') : null;
                    return(<ListItemSpan key={i} style={width}>{iTime}</ListItemSpan>);
                  case 'date':
                    const iDate = listItem[cfg.valueKey] ? momentTz.tz(listItem[cfg.valueKey], momentTz.tz.guess()).format('DD/MM/YYYY') : '--';
                    return(<ListItemSpan key={i} style={width} colorMark={listItem[cfg.colorKey]==='1'} bold={listItem[cfg.boldKey]==='1'}>{iDate}</ListItemSpan>);
                  case 'currency':
                    let price = 0;
                    if (listItem[cfg.valueKey]) {price = listItem[cfg.valueKey];}
                    return(<ListItemSpan key={i} style={width}>${price.toFixed(2)}</ListItemSpan>);
                  case 'warehouse':
                    const addWarehouse = listItem[cfg.addValueKey] && (listItem[cfg.addValueKey] !== listItem[cfg.valueKey]) && listItem.info1 === '0' ? (' -> ' + getWhname(listItem[cfg.addValueKey])) : null;
                    return(<WarehouseSpan addWarehouse={addWarehouse} key={i} style={width}>{getWhname(listItem[cfg.valueKey])}{addWarehouse}</WarehouseSpan>);
                  case 'damagetype':
                    let damageType = 'opt'+listItem[cfg.valueKey].toString();
                    let typeValue = cfg.map[damageType];
                    return(<DamageTypeSpan key={i} status={damageType} style={width}>{typeValue}</DamageTypeSpan>);
                  case 'containerstatus':
                    let containerStatus = 'opt'+ (listItem[cfg.valueKey]?.toString() || "0");
                    let containerStatusValue = cfg.map[containerStatus];
                    return(<ContainerStatusSpan key={i} status={containerStatus} style={width}>{containerStatusValue}</ContainerStatusSpan>);
                  case 'orderstatus':
                    let status = 'opt'+ (listItem[cfg.valueKey]?.toString() || "0");
                    let statusValue = cfg.map[status];
                    return(<OrderStatusSpan key={i} status={status} style={width}>{statusValue}</OrderStatusSpan>);
                  case 'returnOrderstatus':
                    let r_status = 'opt'+ (listItem[cfg.valueKey]?.toString() || "0");
                    let r_statusValue = cfg.map[r_status];
                    return(<ReturnOrderStatusSpan key={i} status={r_status} style={width}>{r_statusValue}</ReturnOrderStatusSpan>);
                  case 'ordertype':
                    let orderType = 'opt'+ (listItem[cfg.valueKey]?.toString() || "");
                    let orderTypeValue = cfg.map[orderType];
                    return(<OrderTypeSpan key={i} status={orderType} style={width}>{orderTypeValue}</OrderTypeSpan>);
                  case 'historyflag':
                    let flag = 'opt'+listItem[cfg.valueKey].toString();
                    let flagValue = cfg.map[flag];
                    return(<HistoryFlagSpan key={i} flag={flag} style={width}>{flagValue}</HistoryFlagSpan>);
                  case 'portaltype':
                    let portal = listItem[cfg.valueKey] !== null && listItem[cfg.valueKey].includes("login as rep") ? "B2B" : "IMS";
                    return(<PortalSpan key={i} portal={portal} style={width}>{portal}</PortalSpan>);
                  case 'cal_times':
                    let calValue = 1;
                    cfg.valueKey.split('|').map( item => {
                      if(listItem[item] === null){
                        const itemValue = listItem['productCategory']['unitSize'];
                        calValue = calValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'string'){
                        const itemValue = parseFloat(listItem[item].replace('m2','').replace('m²','').replace('m',''));
                        calValue = calValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'number'){
                        calValue = calValue * listItem[item];
                      }
                      return null;
                    });
                    return(<ListItemSpan key={i} style={width}>{calValue.toFixed(3)}</ListItemSpan>);
                  case 'cal_times_stock':
                    let currentStockValue = 1;
                    let allocatedStockValue = 1;
                    cfg.valueKey.split('|').map( item => {
                      if(listItem[item] === null){
                        const itemValue = listItem['productCategory']['unitSize'];
                        currentStockValue = currentStockValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'string'){
                        const itemValue = parseFloat(listItem[item].replace('m2','').replace('m²','').replace('m',''));
                        currentStockValue = currentStockValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'number'){
                        currentStockValue = currentStockValue * listItem[item];
                      }
                      return null;
                    });
                    cfg.valueKey1.split('|').map( item => {
                      if(listItem[item] === null){
                        const itemValue = listItem['productCategory']['unitSize'];
                        allocatedStockValue = allocatedStockValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'string'){
                        const itemValue = parseFloat(listItem[item].replace('m2','').replace('m²','').replace('m',''));
                        allocatedStockValue = allocatedStockValue * itemValue ? itemValue : 0;
                      }
                      if(typeof listItem[item] === 'number'){
                        allocatedStockValue = allocatedStockValue * listItem[item];
                      }
                      return null;
                    });
                    return(<ListItemSpan key={i} style={width}>{currentStockValue.toFixed(3).toString() + " / " + (currentStockValue+allocatedStockValue).toFixed(3).toString()}</ListItemSpan>);
                  case 'show_stock':
                    let stockValue = listItem[cfg.valueKey].toString() + " / " + (listItem[cfg.valueKey]+listItem[cfg.valueKey1]).toString();
                    return(<ListItemSpan key={i} style={width}>{stockValue}</ListItemSpan>);
                  case 'stocknumber':
                    let stockNum = listItem[cfg.valueKey] ? listItem[cfg.valueKey] : 0;
                    let uSize = listItem['unitSize'] ? listItem['unitSize'] : 1;
                    let warningSign = stockNum && cfg.warningKey1 && cfg.warningKey2 && listItem[cfg.warningKey1] <= stockNum * 0.3 && listItem[cfg.warningKey2] <= stockNum * 0.3;
                    // warningSign && console.log(warningSign);
                    return(<ListItemSpan title={warningSign ? 'The system has detected a significant decline in recent sales.' : null} key={i} style={width} colorRender={cfg.valueKey==='res'|| warningSign} bold={warningSign} colorWarning={false} colorSuccess={stockNum<=0}>{listItem['unitName']==='m2' ? (stockNum * uSize).toFixed(3) : stockNum}{warningSign ? ' !' : null}</ListItemSpan>);
                  case 'm_sales':
                    const stockNumM = listItem[cfg.valueKey] ? listItem[cfg.valueKey] : 0;
                    const uSizeM = listItem['unitSize'] ? listItem['unitSize'] : 1;
                    return(<ListItemSpan key={i} style={width}>{listItem['unitName']==='m2' ? (stockNumM * uSizeM).toFixed(3) : stockNumM}</ListItemSpan>);
                  case 'monthsCount':
                    return(<ListItemSpan key={i} style={width} colorRender={listItem[cfg.valueKey]?.toFixed(1)<=2.5} colorSuccess={false} bold={listItem[cfg.valueKey]?.toFixed(1)<=2.5}>{listItem[cfg.valueKey]?.toFixed(1) || '--'}</ListItemSpan>);
                  case 'totalstocknumber':
                    let totalNum = 0;
                    cfg.valueKey.split('|').map( item => {
                      if(listItem[item] === null){
                        totalNum = totalNum + 0;
                      }
                      if(typeof listItem[item] === 'number'){
                        totalNum = totalNum + listItem[item];
                      }
                      return null;
                    });
                    let unitSize = listItem['unitSize'] ? listItem['unitSize'] : 1;
                    return(<ListItemSpan key={i} style={width}>{listItem['unitName']==='m2' ? (totalNum * unitSize).toFixed(3) : totalNum.toFixed(1)}</ListItemSpan>);
                  // case 'm_ave_sales':
                  //   let totalNumM = 0;
                  //   cfg.valueKey.split('|').map( item => {
                  //     if(listItem[item] === null){
                  //       totalNumM = totalNumM + 0;
                  //     }
                  //     if(typeof listItem[item] === 'number'){
                  //       totalNumM = totalNumM + listItem[item];
                  //     }
                  //     return null;
                  //   });
                  //   const aveNum = totalNumM / cfg.valueKey.split('|').length;
                  //   const uSizeAve = listItem['unitSize'] ? listItem['unitSize'] : 1;
                  //   return(<ListItemSpan key={i} style={width}>{listItem['unitName']==='m2' ? (aveNum * uSizeAve).toFixed(3) : aveNum.toFixed(1)}</ListItemSpan>);
                  case 'plan_completed_qty':
                    return(<CompletedQtySpan key={i} planQty={listItem[cfg.compareKey]} completedQty={listItem[cfg.valueKey]} style={width}>{listItem[cfg.valueKey]}</CompletedQtySpan>);
                  case 'plan_completed_date':
                    const completedDate = listItem[cfg.valueKey] ? momentTz.tz(listItem[cfg.valueKey], momentTz.tz.guess()).format('DD/MM/YYYY') : '--';
                    return(<CompletedDateSpan key={i} planDate={listItem[cfg.compareKey]} completedDate={listItem[cfg.valueKey]} style={width}>{completedDate}</CompletedDateSpan>);
                  case 'editbtn':
                    if (cfg.adminCompanyOnly) {
                      return(adminCompanyName  === restful.getCookie("companyName") ? <BtnContainer key={i}><button title='Edit' onClick={props.editItem ? props.editItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-editpen"><use xlinkHref="#editpen" /></svg></button></BtnContainer> : null);
                    }
                    if (cfg.control !== undefined) {
                      if (props.nameSpace === "saleOrder" || props.nameSpace === "transferOrder") {
                        return(("4" === restful.getCookie("userType") || "0" === restful.getCookie("userType")) && [1,2,10,11,12].includes(listItem[cfg.control.key1]) && listItem.deliveryFlag!==9 ? <BtnContainer key={i}><button title='Edit' onClick={props.editItem ? props.editItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-editpen"><use xlinkHref="#editpen" /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "sampleOrder") {
                        return(("4" === restful.getCookie("userType") || "0" === restful.getCookie("userType")) && [101,102].includes(listItem[cfg.control.key1]) ? <BtnContainer key={i}><button title='Edit' onClick={props.editItem ? props.editItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-editpen"><use xlinkHref="#editpen" /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "purchaseOrder") {
                        return("0" === restful.getCookie("userType") && [0,1].includes(listItem[cfg.control.key]) ? <BtnContainer key={i}><button title='Edit' onClick={props.editItem ? props.editItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-editpen"><use xlinkHref="#editpen" /></svg></button></BtnContainer> : null);
                      }
                    }
                    return(<BtnContainer key={i}><button title='Edit' onClick={props.editItem ? props.editItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-editpen"><use xlinkHref="#editpen" /></svg></button></BtnContainer>);
                  case 'deletebtn':
                    if (cfg.adminCompanyOnly) {
                      return (adminCompanyName  === restful.getCookie("companyName")  ? <BtnContainer key={i}><button title='Delete' onClick={props.deleteItem ? props.deleteItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer> : null);
                    }
                    if (cfg.control !== undefined) {
                      if (props.nameSpace === "purchaseOrder") {
                        return("0" === restful.getCookie("userType") && [0,1].includes(listItem[cfg.control.key]) ? <BtnContainer key={i}><button title='Delete' onClick={props.deleteItem ? props.deleteItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer> : null);
                      } else if(props.nameSpace === "customer"){
                        return (cfg.control?.userAccounts?.some(element => {return element.toLowerCase() === restful.getCookie("userName").toLowerCase();}) ? <BtnContainer key={i}><button title='Delete' onClick={props.deleteItem ? props.deleteItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer> : null);
                      } else {
                        return (listItem[cfg.control.key] === cfg.control.value && "0" === restful.getCookie("userType") ? <BtnContainer key={i}><button title='Delete' onClick={props.deleteItem ? props.deleteItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer> : null);
                      } 
                    }
                    return (listItem.isSystem ? null : <BtnContainer key={i}><button title='Delete' onClick={props.deleteItem ? props.deleteItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer>); 
                  case 'custombtn':
                    if (cfg.control !== undefined) {
                      if (props.nameSpace === "saleOrder" || props.nameSpace === "transferOrder") {
                        return(listItem[cfg.control.key] === cfg.control.value && "0" === restful.getCookie("userType") && [1,2,12].includes(listItem[cfg.control.key1]) ? <BtnContainer key={i}><button title={cfg.altTitle} onClick={props.customAction ? props.customAction.bind(null, listItem[cfg.valueKey], cfg.route) : null}><svg className={`icon-${cfg.icon}`}><use xlinkHref={`#${cfg.icon}`} /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "sampleOrder") {
                        return(listItem[cfg.control.key] === cfg.control.value && "0" === restful.getCookie("userType") && [101].includes(listItem[cfg.control.key1]) ? <BtnContainer key={i}><button title={cfg.altTitle} onClick={props.customAction ? props.customAction.bind(null, listItem[cfg.valueKey], cfg.route) : null}><svg className={`icon-${cfg.icon}`}><use xlinkHref={`#${cfg.icon}`} /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "productionplans" || props.nameSpace === "purchaseContainer" || props.nameSpace === "shippedContainer") {
                        return(cfg.control.allowed.includes(listItem[cfg.control.key]) && cfg.control?.userTypeValue?.includes(restful.getCookie("userType")) ? <BtnContainer key={i}><button title={cfg.altTitle} onClick={props.customAction ? props.customAction.bind(null, listItem[cfg.valueKey], cfg.route) : null}><svg className={`icon-${cfg.icon}`}><use xlinkHref={`#${cfg.icon}`} /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "returnOrder") {
                        return(cfg.control.allowed.includes(listItem[cfg.control.key]) && cfg.control?.userTypeValue?.includes(restful.getCookie("userType")) && cfg.control?.userAccounts?.some(element => {return element.toLowerCase() === restful.getCookie("userName").toLowerCase();}) ? <BtnContainer key={i}><button title={cfg.altTitle} onClick={props.customAction ? props.customAction.bind(null, listItem[cfg.valueKey], cfg.route) : null}><svg className={`icon-${cfg.icon}`}><use xlinkHref={`#${cfg.icon}`} /></svg></button></BtnContainer> : null);
                      }
                    }
                    return(<BtnContainer key={i}><button title={cfg.altTitle} onClick={props.customAction ? props.customAction.bind(null, listItem[cfg.valueKey], cfg.route) : null}><svg className={`icon-${cfg.icon}`}><use xlinkHref={`#${cfg.icon}`} /></svg></button></BtnContainer>);
                  case 'resetbtn':
                    if (cfg.adminCompanyOnly) {
                      return (adminCompanyName  === restful.getCookie("companyName")  ? <BtnContainer key={i}><button title='Reset' onClick={props.resetItem ? props.resetItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-reverse"><use xlinkHref="#reverse" /></svg></button></BtnContainer> : null);
                    }
                    if (cfg.control !== undefined) {
                      if (props.nameSpace === "saleOrder" || props.nameSpace === "transferOrder") {
                        return ((listItem[cfg.control.key] === 1 && "0" === restful.getCookie("userType") && [1,11].includes(listItem[cfg.control.key1])) || (listItem[cfg.control.key] === 2 && resetOrderUsers.some(element => {return element.toLowerCase() === restful.getCookie("userName").toLowerCase();}) && [1,11].includes(listItem[cfg.control.key1])) ? <BtnContainer key={i}><button title='Reset' onClick={props.resetItem ? props.resetItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-reverse"><use xlinkHref="#reverse" /></svg></button></BtnContainer> : null);
                      } else if (props.nameSpace === "sampleOrder") {
                        return ((listItem[cfg.control.key] === 1 && "0" === restful.getCookie("userType") && [101,102].includes(listItem[cfg.control.key1])) || (listItem[cfg.control.key] === 2 && resetOrderUsers.some(element => {return element.toLowerCase() === restful.getCookie("userName").toLowerCase();}) && [101].includes(listItem[cfg.control.key1])) ? <BtnContainer key={i}><button title='Reset/Decline' onClick={props.resetItem ? props.resetItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-reverse"><use xlinkHref="#reverse" /></svg></button></BtnContainer> : null);
                      }
                    }
                    return null;
                    /*return (listItem.isSystem ? null : <BtnContainer key={i}><button title='Reset' onClick={props.resetItem ? props.resetItem.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-reverse"><use xlinkHref="#reverse" /></svg></button></BtnContainer>); */
                  case 'docketbtn':
                    if (cfg.control !== undefined) {
                      if (cfg.control.value.includes(listItem[cfg.control.key]) && "0" === restful.getCookie("userType") && [1,11,101].includes(listItem[cfg.control.key1])) {
                        return (<BtnContainer key={i}><button title='Download Delivery Docket' onClick={props.downloadDocket ? props.downloadDocket.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-docket"><use xlinkHref="#docket" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  case 'packingbtn':
                    if (cfg.control !== undefined) {
                      if (cfg.control.value.includes(listItem[cfg.control.key]) && "0" === restful.getCookie("userType") && [1,11,101].includes(listItem[cfg.control.key1])) {
                        return (<BtnContainer key={i}><button title='Download Packing Slip' onClick={props.downloadSlip ? props.downloadSlip.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-packingslip"><use xlinkHref="#packingslip" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  case 'summarybtn':
                    if (cfg.control !== undefined) {
                      if (props.nameSpace === "saleOrder") {
                        if ("0" === restful.getCookie("userType") && [1,2,11,12].includes(listItem[cfg.control.key1])) {
                          return (<BtnContainer key={i}><button title='Download Order Summary' onClick={props.downloadSummary ? props.downloadSummary.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "transferOrder") {
                        if ("0" === restful.getCookie("userType") && [1,2,3,4,5].includes(listItem[cfg.control.key1])) {
                          return (<BtnContainer key={i}><button title='Download Transfer Doc' onClick={props.downloadTransferDoc ? props.downloadTransferDoc.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "editdeletelog") {
                        if ("0" === restful.getCookie("userType") && [1,2].includes(listItem[cfg.control.key1])) {
                          return (<BtnContainer key={i}><button title='Download Previous Order Summary' onClick={props.downloadOldSummary ? props.downloadOldSummary.bind(null, listItem) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "priceList") {
                        if ("0" === restful.getCookie("userType")) {
                          return (<BtnContainer key={i}><button title='Download Pricelist' onClick={props.downloadPriceList ? props.downloadPriceList.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "sampleOrder") {
                        if ("0" === restful.getCookie("userType") && [101,102].includes(listItem[cfg.control.key1])) {
                          return (<BtnContainer key={i}><button title='Download Sample Order Summary' onClick={props.downloadSampleSummary ? props.downloadSampleSummary.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "promotion") {
                        if ("0" === restful.getCookie("userType") && listItem[cfg.control.key1] !== null) {
                          return (<BtnContainer key={i}><button title='Download Coupon Code' onClick={props.downloadCouponCode ? props.downloadCouponCode.bind(null, listItem) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      } else if (props.nameSpace === "returnOrder") {
                        if ("0" === restful.getCookie("userType") && listItem[cfg.control.key1] !== null) {
                          return (<BtnContainer key={i}><button title='Download Return Order Docket' onClick={props.downloadReturnOrderDocket ? props.downloadReturnOrderDocket.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-summary"><use xlinkHref="#summary" /></svg></button></BtnContainer>);
                        }
                      }
                    }
                    return null;
                  case 'picklistbtn':
                    if (cfg.control !== undefined) {
                      if (cfg.control.value.includes(listItem[cfg.control.key]) && "0" === restful.getCookie("userType") && [1,11,101].includes(listItem[cfg.control.key1])) {
                        return (<BtnContainer key={i}><button title='Download Warehouse Picking List' onClick={props.downloadPicklist ? props.downloadPicklist.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-picklist"><use xlinkHref="#picklist" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  case 'approvebtn':
                    if (cfg.control !== undefined) {
                      if ("0" === restful.getCookie("userType") && [102].includes(listItem[cfg.control.key1])) {
                        return (<BtnContainer key={i}><button title='Approve' onClick={props.approveSample ? props.approveSample.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-approve"><use xlinkHref="#approve" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  case 'qrylink':
                    let qrytext = listItem;
                    let spanColor = listItem['usedQty']!==undefined ? (listItem['usedQty'] > 0 ? 0 : 1) : null;

                    qrytext && cfg.valueKey.split('.').map( item => {
                      return qrytext = qrytext?.[item];
                    });
                    const bgRenderQL = textFilter && qrytext && textFilter === qrytext;
                    return(<ListItemSpanHand key={i} style={width} spanColor={spanColor} onClick={props.qryLinkItem ? props.qryLinkItem.bind(null, cfg.qryLink, qrytext) : null}><TextSpan bgRender={bgRenderQL}>{qrytext}</TextSpan></ListItemSpanHand>);
                  case 'emailpobtn':
                    if (cfg.control !== undefined) {
                      if ("0" === restful.getCookie("userType") && [0,1].includes(listItem[cfg.control.key])) {
                        return (<BtnContainer key={i}><button title='Send Email' onClick={props.sendPoEmail ? props.sendPoEmail.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-POToSupplier"><use xlinkHref="#POToSupplier" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  case 'finalisepobtn':
                    if (cfg.control !== undefined) {
                      if ("0" === restful.getCookie("userType") && [1].includes(listItem[cfg.control.key])) {
                        return (<BtnContainer key={i}><button title='Finalise PO' onClick={props.finalisePo ? props.finalisePo.bind(null, listItem[cfg.valueKey]) : null}><svg className="icon-FinalisePO"><use xlinkHref="#FinalisePO" /></svg></button></BtnContainer>);
                      }
                    }
                    return null;
                  default:
                    let value = listItem;
                    value && cfg.valueKey.split('.').map( item => {
                      return value = value?.[item];
                    });
                    const isContainerNo = value === "[CONTAINER NO]";
                    // if (textFilter && value && value.toLowerCase().indexOf(textFilter.toLowerCase()) > -1) {
                    //   value = value.replace(new RegExp(textFilter, 'ig'), '<span>' + textFilter + '</span>');
                    // }
                    const bgRender = textFilter && value && textFilter === value;
                    if (value && cfg.maxLength && (value.length + 3) > cfg.maxLength) {
                      return(<ListItemSpan key={i} style={width} title={value}>{value.substring(0,cfg.maxLength) + '...'}</ListItemSpan>);
                    } else {
                      return(<ListItemSpan bold={isContainerNo} colorRender={isContainerNo} key={i} style={width}><TextSpan bgRender={bgRender}>{value}</TextSpan></ListItemSpan>);
                    }
                  }
              })}

              { props.extensionCfg && props.extensionSelection.indexOf(listItem[props.cfgs[0].valueKey])>-1 ? <Extension conf={props.extensionCfg} textFilter={textFilter} qryLinkItem={props.qryLinkItem} item={listItem} nameSpace={props.nameSpace} subListBinder={ props.subListBinder ? props.subListBinder : null } /> : null }

            </ListRow>
          );
        })}
      </ItemList>
    </ListBodyContainer>
  );
}