import React from 'react';
import MainTitle from '../views/main-title';
import ChartContainer from '../containers/chart-container';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import { NavLink } from 'react-router-dom';

const MainMenuTitle = styled.h2`
  font-size: 22px;
  font-family: ${theme.midFont};
  color: ${theme.mainColor};
`;

const SubMenuContainer = styled.ul`
  padding: 20px 0 60px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;

  & li {
    font-size: 16px;
    line-height: 2;

    & a {
      display: block;
      color: ${theme.mainDarkColor};
      text-decoration: underline !important;
    }
  }
`;

export default function(props){
  // console.log(props);
  return (
    <div>
      <MainTitle title={props.conf ? props.conf.title : 'Module Not Found'} />
      {props.conf && props.conf.charts && props.conf.charts.map( (chart, i) => {
        return(
          <ChartContainer key={i} conf={chart} />
        )
      })}
      {props.conf && props.conf.menus && 
        <ul>
          {props.menus.map( (menu, i) => {
            return (
              <li key={i}>
                <MainMenuTitle>{menu.menuName}</MainMenuTitle>
                <SubMenuContainer>
                  {menu.subMenus.map( (subMenu, j) => {
                    return (
                      <li key={j}>
                        <NavLink to={subMenu.menuLink}>{subMenu.menuName}</NavLink>
                      </li>
                    )
                  })}
                </SubMenuContainer>
              </li>
            )
          })}
        </ul>
      }
    </div>
  );
}