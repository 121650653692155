import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import { theme } from '../../configurations/general-conf';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    borderColor: theme.mainBgColor,
    '&:hover': {
      borderColor: theme.mainLighterColor
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: theme.midFont,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : state.isFocused ? theme.mainDeepColor : theme.mainColor,
    backgroundColor: state.isSelected ? theme.mainColor : state.isFocused ? theme.mainBgColor :'white',
    borderBottom: '1px dotted ' + theme.mainBgSeparatorColor,
  }),
}

class ComboBox extends React.Component {
  constructor (props){
    super(props);
    this.state = {
			isClearable: true,
      isDisabled: this.props.disabled ? true : false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      value: null,
    }
  }

  componentDidMount (){
    const { endPoint, staticOptions } = this.props.conf;
    if(endPoint) {
			this.loadOptions(endPoint, this.props.currentValue);
		} 
    if (staticOptions) {
      this.setState({options: staticOptions});
      if(this.props.currentValue){
        this.setState({ value: this.props.currentValue });
      }
    }

  }

  async loadOptions (endPoint, value){
    this.setState({ isLoading: true });
    const res = await axios.get(endPoint.url);
    this.setState({ isLoading: false });
    let options = [];
    res.data.objs.map(item => {
      if(endPoint.valueKey && endPoint.lableKey){
        options.push({ value: item[endPoint.valueKey], label: item[endPoint.lableKey] });
      }
      return null;
    });
    this.setState({ options });
    if (value){
      this.setState({ value });
    }
  }

  changeSelect = (value) => {
    if (this.props.conf.syncChange && value!==this.state.value) {
      this.props.syncChange(value.value);
    }
    if (this.props.conf.syncCustomerChange && value!==this.state.value) {
      if (value !== null) {
        this.props.syncCustomerChange(value.value, this.props.conf.syncRef);
      } else {
        this.props.clearCustomerChange(this.props.conf.syncRef);
      }
    }
    if (this.props.conf.syncCategoryChange && value!==this.state.value) {
      if (value !== null) {
        this.props.syncCategoryChange(value.value, this.props.conf.syncRef);
      } else {
        this.props.clearCategoryChange(this.props.conf.syncRef);
      }
    }
    if (this.props.conf.syncProCatChange && value!==this.state.value) {
      if (value !== null) {
        this.props.syncProCatChange(value.value, this.props.conf.syncRef);
      } else {
        this.props.clearProCatChange(this.props.conf.syncRef);
      }
    }
    if (this.props.conf.autoLoad && value!==this.state.value) {
      this.props.autoLoad(this.props.name, value);
    }
    this.setState({ value });
	}

  customFilter = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }

  render () {
		const { isClearable, isSearchable, isDisabled, isLoading, isRtl, options, value } = this.state;
		return (
      <Select
        styles={customStyles}
        placeholder={this.props.conf.placeholder}
				isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        filterOption={this.customFilter}
        options={options}
        value={value}
        onChange={this.changeSelect}
			/>
		);
	}
}

export default ComboBox;