import React from 'react';
import styled from 'styled-components';
import { theme, adminCompanyName } from '../../configurations/general-conf';
import ComboBox from '../widgets/combo-box';
import * as listApi from '../../api/list-api';
import store from '../../store';
import DateRangeBox from '../widgets/date-range-box';
import * as restful from '../../api/restful-api';

const SearchForm = styled.form`
  background-color: ${theme.mainBgColor};
  height: 168px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const BlockTitle = styled.div`
  height: 150px;
  box-sizing: border-box;
  float: left;
  padding-top: 10px;

  & label {
    font-size: 14px;
    font-family: Univers-LT-Bold;
    color: ${theme.mainDeepColor};
    margin-right: 50px;
    margin-left: 20px;
    margin-top: 7px;
    display: inline-block;
  }
`;

const ButtonsContainer = styled.div`
  height: 168px;
  width: 175px;
  float: right;
  margin-right: 18px;
`;

const SearchButton = styled.button`
  background-color: ${theme.mainColor};
  color: ${theme.mainBrightestColor};
  margin-top: 10px;
  width: 175px;
  height: 38px;
  border: none;
  padding: 0;

  &:hover {
    background-color: ${theme.mainLighterColor};
    transition: background-color .3s;
  }
`;

const ClearButton = styled.button`
  background-color: ${theme.mainBgDeepColor};
  margin-top: 10px;
  width: 175px;
  height: 38px;
  border: none;
  padding: 0;

  &:hover {
    background-color: ${theme.mainBgDeeperColor};
    transition: background-color .3s;
  }
`;

const InputsContainer = styled.div`
  float: left;
  width: 180px;
  margin-top: 10px;
  margin-right: 25px;
`;

const Input = styled.input`
  font-size: 14px;
  max-width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid ${theme.mainBgColor};
  padding: 0 0 0 10px; 

  &:hover {
    border-color: ${theme.mainLighterColor};
  }
`;

class SearchFilterContainer extends React.Component {
  componentDidMount (){
    const qryText = window.localStorage.getItem("qryText")
    // console.log(qryText);
    this.props.filters.map( filter => {
      switch(filter.type) {
				case 'text':
          this.refs[filter.name].value = qryText || filter.currentValue;
          break;
        default:
      }
      return null;
      //return this.refs[filter.name].value = filter.currentValue;
    });

    if (this.props.nameSpace) {
      store.getState()[this.props.nameSpace].filterRefs = this.refs;
    }

    if(qryText){
      window.localStorage.removeItem("qryText");
      this.handleSumbit({preventDefault: () => {}});
    }
  }

  handleSumbit = async (e) => {
    e.preventDefault();
    this.props.filters.slice().map( filter => {
      switch(filter.type) {
				case 'text':
          filter.currentValue = this.refs[filter.name].value.trim();
          break;
          //return Object.assign({}, filter, { currentValue: this.refs[filter.name].value });
        case 'combo':
          if(this.refs[filter.name]){
            filter.currentValue = this.refs[filter.name].state.value ? this.refs[filter.name].state.value : '';
          }
          if(this.props.subListBinder && this.props.subListBinder.name === filter.name){
            store.getState()[this.props.nameSpace][this.props.subListBinder.name] = this.refs[filter.name].state.value ? this.refs[filter.name].state.value.value : this.props.subListBinder.defaultValue;
          }
          break;
        case 'date_range':
          filter.startDate = this.refs[filter.name].state.startDate ? this.refs[filter.name].state.startDate : null;
          filter.endDate = this.refs[filter.name].state.endDate ? this.refs[filter.name].state.endDate : null;
          break;
        case 'custom_date_range':
          filter.startDate = this.refs[filter.name].state.startDate ? this.refs[filter.name].state.startDate : null;
          filter.endDate = this.refs[filter.name].state.endDate ? this.refs[filter.name].state.endDate : null;
          break;
        default:
          //return filter;
      }
      return filter;
    });
    // console.log(this.props.filters);
    if(this.props.nameSpace){
      this.refs.searchbtn.disabled = true;
      store.getState()[this.props.nameSpace].extensionSelection = [];
      await listApi.getList(this.props.nameSpace, 1);
      this.refs.searchbtn.disabled = false;
    }
  }

  render () {
		return (
	  	<SearchForm onSubmit={this.handleSumbit}>
				<BlockTitle> <label> Filter: </label> </BlockTitle>
				<ButtonsContainer>
					<SearchButton type="submit" ref="searchbtn"> Search </SearchButton>
					<ClearButton type="reset" onClick={this.props.resetResults}> Reset </ClearButton>
				</ButtonsContainer>
				{this.props.filters.map((filter,i) => {
					switch(filter.type) {
						case 'text':
							return (
								<InputsContainer key={filter.name+i}>
									<Input type={filter.type} ref={filter.name} placeholder={filter.placeholder} />
								</InputsContainer>
              );
            case 'combo':
              let displayField = true;
              if(filter.adminCompanyOnly === true){
                displayField = adminCompanyName  === restful.getCookie("companyName") ? true : false;
              }
              if(filter.adminUserOnly === true){
                displayField = "0" === restful.getCookie("userType") ? true : false;
              }
              return (
                displayField ?
                <InputsContainer key={filter.name+i}>
                  <ComboBox conf={filter.conf} ref={filter.name} currentValue={filter.currentValue} />
                </InputsContainer>
                : null
              );
            case 'date_range':
              let displayDateRange = true;
              if(filter.adminCompanyOnly === true){
                displayDateRange = adminCompanyName  === restful.getCookie("companyName") ? true : false;
              }
              return (
                displayDateRange ?
                <DateRangeBox key={filter.name+i} conf={filter.conf} ref={filter.name} startDate={filter.startDate} endDate={filter.endDate} />
                : null
                );
            case 'custom_date_range':
              return (<DateRangeBox key={filter.name+i} conf={filter.conf} ref={filter.name} startDate={filter.startDate} endDate={filter.endDate} />);
						default:
					}
					return null;
        })}
			</SearchForm>
	  );
	}
}

export default SearchFilterContainer;