import React from 'react';
//import { connect } from 'react-redux';
import MainTitle from '../views/main-title';
import store from '../../store';
import { addAsyncReducer } from '../../store';
import Filters from './search-filter-container';
import List from '../views/list';
import * as listApi from '../../api/list-api';
import Footer from './list-footer-container';

class ListContainer extends React.Component {
  // UNSAFE_componentWillMount () {
  //   //console.log(store.getState());
  //   if(this.props.conf && !store.getState()[this.props.conf.nameSpace]){
  //     addAsyncReducer(store, this.props.conf);
  //   }
  // }

  constructor (props) {
    super(props);
    if(props.conf && !store.getState()[props.conf.nameSpace]){
      addAsyncReducer(store, props.conf);
    }
  }

  componentDidMount (){
    if(this.props.conf && this.props.conf.nameSpace && !this.props.conf.hideList){
      listApi.getList(this.props.conf.nameSpace);
    }
  }

  approveSample = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to approve this Sample Order?")){
      listApi.approveSample(this.props.conf.nameSpace, listItemId);
    } else {
      console.log('cancelled');
    }
  }

  sendPoEmail = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to send this PO's notification email to supplier?")){
      listApi.sendPoEmail(this.props.conf.nameSpace, listItemId);
    } else {
      console.log('cancelled');
    }
  }

  finalisePo = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to approve this PO for supplier?")){
      listApi.finalisePo(this.props.conf.nameSpace, listItemId);
    } else {
      console.log('cancelled');
    }
  }

  deleteListItem = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to delete the item?")){
      listApi.deleteListItem(this.props.conf.nameSpace, listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadCouponCode = (listItem, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download coupon code PDF?")){
      listApi.downloadCouponCode(listItem);
    } else {
      console.log('cancelled');
    }
  }

  downloadSlip = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download packing slip?")){
      listApi.downloadPackingSlip(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadDocket = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download delivery docket?")){
      listApi.downloadDeliveryDocket(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadSummary = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download order summary?")){
      listApi.downloadOrderSummary(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadSampleSummary = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download sample order summary?")){
      listApi.downloadSampleOrderSummary(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadPicklist = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download warehouse picking list?")){
      listApi.downloadPicklist(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadReturnOrderDocket = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download Return Order Summary?")){
      listApi.downloadReturnOrderDocket(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadTransferDoc = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download Transfer Doc?")){
      listApi.downloadTransferDoc(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  downloadOldSummary = (listItem, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download previous Order Summary?")){
      listApi.downloadOldSummary(listItem);
    } else {
      console.log('cancelled');
    }
  }

  downloadPriceList = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to download Pricelist?")){
      listApi.downloadPriceList(listItemId);
    } else {
      console.log('cancelled');
    }
  }

  editListItem = (listItemId, e) => {
    e.stopPropagation();
    this.props.history.push(window.location.pathname + '/edit/' +listItemId);
  }

  qryLinkItem = (listPath, qryText, e) => {
    e.stopPropagation();
    // console.log(listPath);
    try {
      navigator.clipboard.writeText(qryText);
    } catch (err) {
      console.log(err);
    }
    window.localStorage.setItem("qryText", qryText);
    this.props.history.push(listPath);
  }

  customAction = (listItemId, route, e) => {
    e.stopPropagation();
    this.props.history.push(window.location.pathname + route + '/' +listItemId);
  }

  resetListItem = (listItemId, e) => {
    e.stopPropagation();
    if(window.confirm("Confirm to revert the order and unallocated the barcode(s)?")){
      listApi.resetListItem(this.props.conf.nameSpace, listItemId);
    } else {
      console.log('cancelled');
    }
  }

  resetResults = () => {
    if(this.props.conf){
      store.getState()[this.props.conf.nameSpace].selection = [];
      store.getState()[this.props.conf.nameSpace].extensionSelection = [];
      store.getState()[this.props.conf.nameSpace].currentQuerry = null;
      const { filterRefs } = store.getState()[this.props.conf.nameSpace];
      store.getState()[this.props.conf.nameSpace].filters.map(filter => {
        switch(filter.type) {
          case 'text':
            filterRefs[filter.name].value = '';
            filter.currentValue = null;
            break;
          case 'combo':
            if(filterRefs[filter.name]){
              filterRefs[filter.name].setState({ value: null });
              filter.currentValue = null;
            }
            if(this.props.conf.subListBinder && this.props.conf.subListBinder.name === filter.name){
              store.getState()[this.props.conf.nameSpace][this.props.conf.subListBinder.name] = this.props.conf.subListBinder.defaultValue;
            }
            break;
          case 'date_range':
            filterRefs[filter.name].setState({ startDate: null, endDate: null });
            filter.startDate = null;
            filter.endDate = null;
            break;
          case 'custom_date_range':
            filterRefs[filter.name].setState({ startDate: null, endDate: null });
            filter.startDate = null;
            filter.endDate = null;
            break;
          default:
        }
        return filter;
      });
      if (this.props.conf.hideList){
        store.dispatch({ type: 'get_' + this.props.conf.nameSpace, data: [] });
      } else if(this.props.conf.nameSpace){
        listApi.getList(this.props.conf.nameSpace, 1);
      }
    }
  }

  changeSelection = (listItemId, e) => {
    if (e.target.checked) {
      store.dispatch({type: 'add_' + this.props.conf.nameSpace + '_to_selection', listItemId});
    } else {
      store.dispatch({type: 'remove_' + this.props.conf.nameSpace + '_from_selection', listItemId});
    }
  }

  toggleExtension = (listItemId) => {
    if ((store.getState()[this.props.conf.nameSpace].extensionSelection.indexOf(listItemId)>-1)===false) {
      store.dispatch({type: 'add_' + this.props.conf.nameSpace + '_extension', listItemId});
    } else {
      store.dispatch({type: 'remove_' + this.props.conf.nameSpace + '_extension', listItemId});
    }
  }

  render (){
    const { conf } = this.props;
    return (
      <React.Fragment>
        <MainTitle title={ conf ? conf.title + ' List' : 'Module Not Found'} />
        <Filters
          filters={ conf ? store.getState()[conf.nameSpace].filters : [] }
          nameSpace={ conf ? conf.nameSpace : null }
          subListBinder={ conf && conf.subListBinder ? conf.subListBinder : null }
          resetResults={this.resetResults}
        />
        <List
          list={ conf ? store.getState()[conf.nameSpace].list : [] }
          listStatus={ conf ? store.getState()[conf.nameSpace].status : null }
          selection={ conf ? store.getState()[conf.nameSpace].selection : [] }
          extensionSelection={ conf ? store.getState()[conf.nameSpace].extensionSelection : [] }
          cfgs={ conf && conf.tableCfg ? conf.tableCfg : [] }
          extensionCfg={ conf && conf.extensionCfg ? conf.extensionCfg : null }
          approveSample={this.approveSample}
          sendPoEmail={this.sendPoEmail}
          finalisePo={this.finalisePo}
          deleteItem={this.deleteListItem}
          downloadCouponCode={this.downloadCouponCode}
          downloadSlip={this.downloadSlip}
          downloadDocket={this.downloadDocket}
          downloadSummary={this.downloadSummary}
          downloadSampleSummary={this.downloadSampleSummary}
          downloadPicklist={this.downloadPicklist}
          downloadReturnOrderDocket={this.downloadReturnOrderDocket}
          downloadTransferDoc={this.downloadTransferDoc}
          downloadOldSummary={this.downloadOldSummary}
          downloadPriceList={this.downloadPriceList}
          editItem={this.editListItem}
          qryLinkItem={this.qryLinkItem}
          customAction={this.customAction}
          resetItem={this.resetListItem}
          changeSelection={this.changeSelection}
          toggleExtension={this.toggleExtension}
          nameSpace={ conf ? conf.nameSpace : null }
          subListBinder={ conf && conf.subListBinder ? conf.subListBinder : null }
          showCheckbox={conf && conf.showCheckbox ? true : false}
          buttonAreaWidth={ conf && (conf.buttonAreaWidth || 238)}
        />
        { conf ? 
          <Footer
            nameSpace={conf.nameSpace}
            buttons={conf.footerButtons}
            resetResults={this.resetResults}
            history={this.props.history}
          />
        : null }
      </React.Fragment>
    );
  }
}

export default ListContainer;

// const mapStateToProps = function(store) {

//   return {
//     store: store
//   };

// };

// export default connect(mapStateToProps)(ListContainer);