import React from 'react';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';

const SubListItem = styled.li`
  float: left;
  width: ${ props => (101 - props.itemsPerRow) / props.itemsPerRow + '%' };
  margin-right: 1%;
  margin-bottom: ${props => props.marginBottom + 'px'};
  position: relative;
  border: 1px solid ${theme.mainLighterColor};

  :before {
      content: "";
      display: block;
      padding-top: 100%;
  }

  &:nth-child(${props => props.itemsPerRow}n) {
    margin-right: 0;
  }
`;

const GridImageContainer = styled.div`
  position: absolute;
  top: 0;

  & img {
    border-style: none;
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }
`;

const GridName = styled.span`
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 10px;
  width: 100%;
  color: ${theme.mainColor};
`;

const GridCode = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 5px; 
  color: ${theme.mainDarkColor};
`;

const GridBarcode = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${
    props => props.bgRender ? 'yellow' : '#fff'
  };
  padding: 5px; 
  color: ${theme.mainDarkColor};
`;

const GridSku = styled.span`
  position: absolute;
  bottom: -25px;
  background-color: ${
    props => props.bgRender ? 'yellow' : 'lightcyan'
  };
  padding: 5px; 
  color: ${theme.mainDarkColor};
`;

const SubListNumberContainer = styled.div`
  text-align: center;
  line-height: 26px;
  color: ${theme.mainColor};
  position: absolute;
  right: 0;

  & span:first-child {
    background: ${theme.mainBgColor};
    padding: 5px;
  }

  & span:last-child {
    background: ${theme.mainColor};
    color: #fff;
    padding: 5px;
  }
`;

export default (props) => {
  const { subListCfg, subListItem, textFilter } = props;

  return(
    <SubListItem
      itemsPerRow={subListCfg.subListItemPerRow ? subListCfg.subListItemPerRow : 5 }
      marginBottom={subListCfg.subListItemMargin ? subListCfg.subListItemMargin: 10}
    >
      {subListCfg.subListFields.map( (field, i) => {
        const bgRender = textFilter && subListItem[field.valueKey] && textFilter === subListItem[field.valueKey];
        switch (field.type) {
          case 'image':
            let value = subListItem;
            field.valueKey.split('.').map( item => {
              return value = value[item];
            });
            return (
              <GridImageContainer key={i}>{ value ? <img src={ restful.getCookie("fileurl") + value } alt="Item" /> : '' }</GridImageContainer>
            );
          case 'name':
            return (
              <GridName key={i}>{ subListItem[field.valueKey] }</GridName>
            );
          case 'code':
            return (
              <GridCode key={i}>{ subListItem[field.valueKey] }</GridCode>
            );
          case 'barcode':
            return (
              subListItem[field.valueKey] ? <GridBarcode key={i} bgRender={bgRender}>{ subListItem[field.valueKey] }</GridBarcode> : null
            );
          case 'sku':
            return (
              subListItem[field.valueKey] ? <GridSku key={i} bgRender={bgRender}>{ subListItem[field.valueKey] }</GridSku> : null
            );
          case 'number':
              return (
                <SubListNumberContainer key={i}><span>{ field.attLabelKey ? subListItem[field.attLabelKey] : field.label }</span><span>{subListItem[field.valueKey]}</span></SubListNumberContainer>
              );
          case 'returnNumber':
              return (
                <SubListNumberContainer key={i}><span>{ field.attLabelKey ? subListItem[field.attLabelKey] : field.label }</span><span>{subListItem[field.valueKey1] ? subListItem[field.valueKey1] : 0} / {subListItem[field.valueKey]}</span></SubListNumberContainer>
              );
          default:
            return null;
        }
      })}
    </SubListItem>
  );
}