import axios from 'axios';
import store from '../store';
import moment from 'moment';
import * as restful from '../api/restful-api';
import { resturl, fileurl } from '../configurations/general-conf';

export async function getList (nameSpace, toPage) {
  if(toPage){
    store.getState()[nameSpace].currentPage = toPage;
  }
  const { pageSize, currentPage, endPoint, orderBy, filters } = store.getState()[nameSpace];
  //console.log(filters);
  const pageStr = '&_pageStart=' + (currentPage-1)*pageSize + '&_pageSize=' + pageSize;
  let query = '';
  filters.map( filter => {
    switch (filter.type){
      case 'text':
        query +=  filter.currentValue ? ('&qryStr=' + filter.currentValue) : '';
        break;
      case 'combo':
        //console.log(filter.conf.defaultValue + '/' + (filter.currentValue ? filter.currentValue.value : ''));
        if (filter.currentValue && filter.currentValue.value) {
          query += '&' + filter.name + '=' + (filter.currentValue ? filter.currentValue.value : '');
        } else if (filter.conf.defaultValue) {
          query += '&' + filter.name + '=' + filter.conf.defaultValue;
        }
        break;
      case 'date_range':
        query += filter.startDate ? '&qryFrom=' + moment(filter.startDate).format('YYYY-MM-DD') : '';
        query += filter.endDate ? '&qryTo=' + moment(filter.endDate).format('YYYY-MM-DD') : '';
        break;
      case 'custom_date_range':
        query += filter.startDate ? '&' + filter.name + 'From=' + moment(filter.startDate).format('YYYY-MM-DD') : '';
        query += filter.endDate ? '&' + filter.name + 'To=' + moment(filter.endDate).format('YYYY-MM-DD') : '';
        break;
      default:
    }
    return null;
  });
  let sqlPart= '?_time=0'+ query + pageStr ;
  if (orderBy) {
    sqlPart += '&orderBy=' + orderBy;
  }
  if (query) {
    store.getState()[nameSpace].currentQuerry = '?_time=0' + query;
  }
  //console.log(store.getState()[nameSpace].currentQuerry);
  store.dispatch({ type: 'set_status_' + nameSpace, status: null });
  // console.log(store.getState()[nameSpace].status);
  setTimeout(() => {
    store.getState()[nameSpace].status !== 'loaded' && store.dispatch({ type: 'set_status_' + nameSpace, status: 'loading' });
  }, 250);
  sqlPart = sqlPart.replace("#", '%23');
  const res = await axios.get(endPoint + sqlPart);
  // console.log(res.data);
  store.dispatch({ type: 'get_' + nameSpace, data: res.data });
  store.dispatch({ type: 'set_status_' + nameSpace, status: 'loaded' });
}

export async function createListItem(props, listItem) {
  const { endPoint } = props.conf;
  const res = await axios.post(endPoint + '/new',listItem);

  if (res.data.result === 1) {
    const currentPath = window.location.pathname;
    if (currentPath.indexOf('/new') > -1) {
      const backPath = currentPath.replace('/new', '');
      props.history.push(backPath);
      return res.data.objs[0];
    }
    if (currentPath.indexOf('/edit') > -1) {
      const backPath = props.match.path.replace('/edit/:listItemId', '');
      props.history.push(backPath);
    }
  } else {
    window.alert(res.data.msg);
    return "error";
  }
}

export async function updateListItem(props, listItem, listItemId) {
  const { endPoint } = props.conf;
  const res = await axios.put(endPoint + '/' + listItemId + '/edit', listItem);

  //console.log(res.data);

  if (res.data.result === 1) {
    if(listItem.reStatus){
      const reListItem = {...listItem, status: listItem.reStatus};
      // console.log(reListItem);
      await axios.put(endPoint + '/' + listItemId + '/edit', reListItem);
    }
    const currentPath = window.location.pathname;
    if (currentPath.indexOf('/edit') > -1) {
      const backPath = props.match.path.replace('/edit/:listItemId', '');
      props.history.push(backPath);
    } else if (props.conf.route) {
      const backPath = props.match.path.replace(props.conf.route + "/:listItemId", "");
      props.history.push(backPath);
    }
  } else {
    window.alert(res.data.msg);
    return "error";
  }
}

export async function deleteListItem(nameSpace, listItemId) {
  const { endPoint } = store.getState()[nameSpace];
  const res = await axios.delete(endPoint + '/del/' + listItemId);
  if (res.data.result === 1) {
    store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
    return res;
  } else {
    window.alert(res.data.msg);
  }
}

export async function deleteBatch(nameSpace) {
  const { endPoint, selection } = store.getState()[nameSpace];
  const jsonData = { "batchDelete": selection }
  const res = await axios.post(endPoint + '/batch', jsonData);
  if (res.data.result === 1) {
    selection.map(listItemId => {
      store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
      store.dispatch({ type: 'remove_' + nameSpace + '_from_selection', listItemId });
      return true;
    });
  } else {
    window.alert(res.data.msg);
  }
}

export async function invoicedBatch(nameSpace) {
  const { endPoint, selection } = store.getState()[nameSpace];
  const jsonData = { "batchDelete": selection }
  const res = await axios.post(endPoint + '/invoiced', jsonData);
  selection.map(listItemId => {
      store.dispatch({ type: 'remove_' + nameSpace + '_from_selection', listItemId });
      return true;
  });
  window.alert(res.data.msg);
}

export async function processedBatch(nameSpace) {
  const { endPoint, selection } = store.getState()[nameSpace];
  const jsonData = { "batchDelete": selection }
  const res = await axios.post(endPoint + '/processed', jsonData);
  selection.map(listItemId => {
      store.dispatch({ type: 'remove_' + nameSpace + '_from_selection', listItemId });
      return true;
  });
  window.alert(res.data.msg);
}

export async function exportCSV(nameSpace, exUrl) {
  if(store.getState()[nameSpace].currentQuerry){
    const res = await axios.get(resturl+exUrl+store.getState()[nameSpace].currentQuerry);
    if (res.data.result === 1) {
      window.open(fileurl + '/' + restful.getCookie("companyUuid") +'/' + res.data.objs[0].fileUrl);
    } else {
      window.alert(res.data.msg);
    }
  }
}

export async function directExportCSV(exUrl) {
  const res = await axios.get(resturl+exUrl);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + restful.getCookie("companyUuid") +'/' + res.data.objs[0].fileUrl);
  } else {
    window.alert(res.data.msg);
  }
}

export async function resetListItem(nameSpace, listItemId) {
  const { endPoint } = store.getState()[nameSpace];
  let res;
  if (endPoint === '/saleorders') {
    res = await axios.put(endPoint + '/' + listItemId + '/cancelwos');
  } else {
    res = await axios.put(endPoint + '/' + listItemId + '/cancelbyweb');
  }
 
  if (res.data.result === 1) {
    store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
    return res;
  } else {
    window.alert(res.data.msg);
  }
}

export async function downloadPackingSlip(listItemId) {
  const res = await axios.put(resturl + '/reports/sales/packingslip/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Packing Slip Error: " + res.data.msg);
  }
}

export async function downloadDeliveryDocket(listItemId) {
  const res = await axios.put(resturl + '/reports/sales/deliverydocket/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Delivery Docket Error: " + res.data.msg);
  }
}

export async function downloadOrderSummary(listItemId) {
  const res = await axios.put(resturl + '/reports/sales/ordersummary/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Order Summary Error: " + res.data.msg);
  }
}

export async function downloadSampleOrderSummary(listItemId) {
  const res = await axios.put(resturl + '/reports/sales/sampleordersummary/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Sample Order Summary Error: " + res.data.msg);
  }
}

export async function downloadPicklist(listItemId) {
  const res = await axios.put(resturl + '/reports/sales/pickinglist/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Warehouse Picking List Error: " + res.data.msg);
  }
}

export async function downloadReturnOrderDocket(listItemId) {
  const res = await axios.put(resturl + '/reports/returns/returndocket/' + listItemId );
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Return Order Docket Error: " + res.data.msg);
  }
}

export async function downloadTransferDoc(listItemId) {
  const res = await axios.put(resturl + '/transferorders/' + listItemId + '/report');
  if (res.data.result === 1) {
    window.open(fileurl + '/' + res.data.msg);
  } else {
    window.alert("Download Transfer Doc Error: " + res.data.msg);
  }
}

export async function downloadOldSummary(listItem) {
  const summaryUrl = listItem.logContent;
  if (summaryUrl !==  null) {
    window.open(fileurl + '/' + summaryUrl);
  } else {
    window.alert("Download Order Summury Error: No Order Summary available.");
  }
}

export async function downloadCouponCode(listItem) {
  const couponCodeUrl = listItem.info2;
  if (couponCodeUrl !==  null) {
    window.open(fileurl + '/' + couponCodeUrl);
  } else {
    window.alert("Download Coupon Code PDF Error: No PDF available.");
  }
}

export async function downloadPriceList(listItemId) {
  const res = await axios.put(resturl + '/reports/pricelist/pdf/' + listItemId);
  if (res.data.result === 1) {
    window.open(fileurl + res.data.msg);
  } else {
    window.alert("Download Pricelist Error: " + res.data.msg);
  }
}

export async function approveSample(nameSpace, listItemId) {
  const { endPoint } = store.getState()[nameSpace];
  const res = await axios.put(endPoint + '/' + listItemId + '/oos/confirm');
  if (res.data.result === 1) {
    store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
    return res;
  } else {
    window.alert(res.data.msg);
  }
}

export async function sendPoEmail(nameSpace, listItemId) {
  const { endPoint } = store.getState()[nameSpace];
  const res = await axios.put(endPoint + '/' + listItemId + '/sendemail');
  if (res.data.result === 1) {
    // store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
    store.dispatch({ type: 'update_single_' + nameSpace, data: {[nameSpace + 'Uuid']: listItemId, status: 1} });
    window.alert("New PO notification email is sent to supplier.");
    return res;
  } else {
    window.alert(res.data.msg);
  }
}

export async function finalisePo(nameSpace, listItemId) {
  const { endPoint } = store.getState()[nameSpace];
  const res = await axios.put(endPoint + '/' + listItemId + '/confirm');
  if (res.data.result === 1) {
    // store.dispatch({ type: 'del_single_' + nameSpace, listItemId });
    store.dispatch({ type: 'update_single_' + nameSpace, data: {[nameSpace + 'Uuid']: listItemId, status: 2} });
    window.alert("PO is confirmed and email notification is sent to supplier.");
    return res;
  } else {
    window.alert(res.data.msg);
  }
}