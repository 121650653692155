import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { theme, newBarcodeUsers } from '../../configurations/general-conf';
import ComboBox from '../widgets/combo-box';
import AbsGridSubitem from '../views/abs-grid-subitem';
import RelGridSubitem from '../views/rel-grid-subitem';
import CascadeList from '../widgets/cascade-list';
import store from '../../store';
import * as restful from '../../api/restful-api';
import moment from 'moment';
import BarChartPN from '../widgets/bar-chart-pn';
import _ from 'lodash';

const SubListContainer = styled.ul`
  padding: 10px;
`;

const CreateItemContainer = styled.div`
  padding: 10px;
`;

const InputsContainer = styled.div`
  float: left;
  width: 180px;
  margin-top: 10px;
  margin-right: 25px;
`;

const Input = styled.input`
  font-size: 14px;
  max-width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid ${theme.mainBgColor};
  padding: 0 0 0 10px; 

  &:hover {
    border-color: ${theme.mainLighterColor};
  }
`;

const Button = styled.button`
  background-color: ${theme.mainLighterColor};
  margin-top: 10px;
  width: 100px;
  height: 38px;
  border: none;
  padding: 0;
  color: #fff;

  &:hover {
    background-color: ${theme.mainLightColor};
    transition: background-color .3s;
  }
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      subList: [],
      showCreateForm: false,
      disableCreate: false
    }
  }

  async loadSubList () {
    const { conf, item, nameSpace, subListBinder } = this.props;
    // console.log(qryLinkItem);
    if(conf.subList){
      const { feedType, key, subKey, endPoint, contorlKey, orderKey } = conf.subList.dataSource;
      if( feedType === 'restapi') {
        let restUrl = endPoint.replace(':uuid', item[key]);
        if(subListBinder){
          restUrl = restUrl.replace(':' + subListBinder.name, store.getState()[nameSpace][subListBinder.name]);
        }
        // if(orderKey){
        //   restUrl += '?orderBy=' + orderKey;
        // }
        const res = await axios.get(restUrl);
        if(res.data.objs && res.data.objs.length){
          let subList = subKey ? res.data.objs[0][subKey] : res.data.objs;
          if(orderKey){
            subList = _.orderBy(subList, [orderKey]);
          }
          const control = contorlKey ? res.data.objs[0][contorlKey] : null;
          // console.log(control);
          // console.log(subList);
          this.setState({ subList: subList, control: control });
        }
      }
      if ( feedType === 'subitem') {
        // console.log(item[key]);
        this.setState({ subList: item[key] });
      }
      if ( feedType === 'item') {
        let subList = [];
        for (let i = 24; i >= 1; i--) {
          let month = moment().subtract(i, 'months').format('MM/YY');
          let monthData = {
            name: month,
            Quantity: Number((item['m'+i] * item.unitSize).toFixed(0))
          };
          subList.push(monthData);
        }
        this.setState({ subList: subList });
      }
    }
  }

  componentDidMount () {
    this.loadSubList();
  }

  showForm = () => {
    this.setState({showCreateForm: true});
  }

  createItem = async () => {
    let newItem = { isValid: [] };
    const { createItem, subList } = this.props.conf;
    createItem.fields.map(field => {
      switch(field.type){
        case 'combo':
          const comboValue = this.refs[field.name].state.value;
          if (comboValue) {
            newItem[field.name] = comboValue.value;
          } else if (field.mandatory && !comboValue) {
            newItem.isValid.push(1);
          }
          break;
        default:
          const value = this.refs[field.name].value;
          if (value) {
            newItem[field.name] = value;
          } else if (field.mandatory && !value) {
            newItem.isValid.push(1);
          }
          break;
      }
      return null;
    });

    if (subList && subList.dataSource && subList.dataSource.key) {
      newItem[subList.dataSource.key] = this.props.item[subList.dataSource.key];
    }

    if(!newItem.isValid.length){
      delete newItem.isValid;
      //console.log(newItem);
      this.setState({disableCreate: true});

      const res = await axios.post(createItem.endPoint + '/new', newItem);
      if (res.data.result === 1) {
        this.setState({showCreateForm: false, disableCreate: false});
        this.loadSubList();
      } else {
        window.alert(res.data.msg);
        this.setState({ disableCreate: false });
      }
    }
  }

  delItem = async (itemId) => {
    if(window.confirm("Do you really want to proceed?")){
      const { createItem } = this.props.conf;
      const res = await axios.delete(createItem.endPoint + '/del/' + itemId);
      if (res.data.result === 1) {
        this.loadSubList();
      } else {
        window.alert(res.data.msg);
      }
    } else {
      console.log('cancelled');
    }
  }

  render () {
    const subListCfg = this.props.conf.subList;
    const { createItem } = this.props.conf;
    const { subList, control } = this.state;
    const chartConf = this.props.conf.subList.conf;
    //console.log(subList);
    return (
      <React.Fragment>
        { createItem && newBarcodeUsers.some(element => {return element.toLowerCase() === restful.getCookie("userName").toLowerCase();}) ?
          <CreateItemContainer className="clearfix" onClick={function(event){event.stopPropagation();}}>
            {this.state.showCreateForm ?
            <div>
              {createItem.fields.map( (field, i) => {
                switch(field.type) {
                  case 'text':
                    return (
                      <InputsContainer key={field.name+i}>
                        <Input type={field.type} ref={field.name} placeholder={field.placeholder} maxLength={ field.maxLength ? field.maxLength : null }/>
                      </InputsContainer>
                    );
                  case 'combo':
                    let newConf = field.conf;
                    if(field.conf.endPoint){
                      const newUrl = field.conf.endPoint.url.replace(':uuid', this.props.item[this.props.conf.subList.dataSource.key]);
                      const newEndpoint = Object.assign({}, field.conf.endPoint, { url: newUrl });
                      newConf = Object.assign({}, field.conf, { endPoint: newEndpoint });
                    }
                    return (
                      <InputsContainer key={field.name+i}>
                        <ComboBox conf={newConf} ref={field.name} currentValue={field.currentValue}/>
                      </InputsContainer>
                    );
                  default:
                }
                return null;
              })}
              <Button disabled={this.state.disableCreate} onClick={this.createItem}>Create New</Button>
            </div>
            : <Button onClick={this.showForm}>{createItem.title}</Button> }
          </CreateItemContainer>
        : null }

        { subList.length ? 
          <SubListContainer className="clearfix" onClick={function(event){event.stopPropagation();}}>
            {subListCfg.subListType==='absolute_grid' && subList.map( (subListItem, j) => {
              return <AbsGridSubitem key={j} subListCfg={subListCfg} subListItem={subListItem} textFilter={this.props.textFilter} />;
            })}

            {subListCfg.subListType==='relative_grid' && subList.map( (subListItem, j) => {
              return <RelGridSubitem key={j} subListCfg={subListCfg} subListItem={subListItem} delItem={this.delItem} qryLinkItem={this.props.qryLinkItem}/>;
            })}

            {subListCfg.subListType==='cascade_list' ? 
              <CascadeList conf={subListCfg.conf} subList={subList} control={control}/>
            : null}

            {subListCfg.subListType==='bar_chart' ?
              <BarChartPN
                data={ subList }
                nameSpace={ chartConf ? chartConf.nameSpace : null }
                chartWidthPercentage={ chartConf ? chartConf.chartWidthPercentage : null }
                chartHeightPx={ chartConf ? chartConf.chartHeightPx : null }
                warningQty={ chartConf ? chartConf.warningQty : null }
              />
              : null}
          </SubListContainer>
        : null }
      </React.Fragment>
    );
  }
}